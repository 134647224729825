<template>
  <auth>
    <!-- begin right-content -->
    <div class="right-content">
      <!-- begin login-header -->
      <div class="login-header">
        <div class="brand">
          <div class="img-responsive">
            <img src="/logo.jpg" alt="CoOperative BEEDEE" />
          </div>
          <div class="brand-name"><span>Co-op</span><span>Bid</span></div>
          <small>Bidbonds fast</small>
        </div>
        <div class="icon">
          <i class="fa fa-sign-in"></i>
        </div>
      </div>
      <!-- end login-header -->
      <div v-if="show_verified" class="alert alert-success">
        <p>Your account has been verified. You may now log in.</p>
      </div>
      <!-- begin login-content -->
      <div class="login-content" id="main-content">
        <form class="margin-bottom-0" @submit.prevent="login" id="login-form">
          <div class="form-group m-b-15">
            <input
              v-model="user.email"
              autocomplete="email"
              class="form-control form-control-lg"
              placeholder="Email Address"
              required
              type="email"
              name="email"
            />
          </div>
          <div class="form-group m-b-15">
            <input
              v-model="user.password"
              class="form-control form-control-lg"
              placeholder="Password"
              required
              autocomplete="current-password"
              type="password"
              name="password"
            />
          </div>
          <div class="login-buttons">
            <button class="btn btn-success btn-block btn-lg" type="submit">
              Sign in
            </button>
            <g-signin-button
              class="btn-outline-primary btn btn-block btn-lg"
              :params="googleSignInParams"
              @error="onSignInError"
              @success="onSignInSuccess"
            >
              <span class="icon" /> Sign in with Google
            </g-signin-button>
          </div>
          <div class="m-t-20 m-b-40 p-b-40 text-inverse">
            Not a member yet? Click
            <router-link :to="{ name: 'register' }" class="text-success"
              >here</router-link
            >
            to register.
          </div>
          <p>
            <router-link :to="{ name: 'forgot-password' }" class="link">
              Forgot your password?
            </router-link>
          </p>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; {{ app }} All Right Reserved 2021
          </p>
        </form>
      </div>
      <!-- end login-content -->
    </div>
    <!-- end right-container -->
  </auth>

  <!--  </div>-->
</template>

<script>
import Auth from "@/components/Auth";
import GSignInButton from "vue-google-signin-button";
import Vue from "vue";

Vue.use(GSignInButton);

export default {
  components: {
    Auth,
  },
  data() {
    return {
      app: process.env.VUE_APP_NAME,
      user: {
        email: "",
        password: "",
        access_token: "",
      },
      access_token: "",
      error: "",
      show_verified: false,
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      },
    };
  },
  computed: {
    loggeduser() {
      return this.$store.getters.getAuthUser;
    },
  },

  mounted() {
    if (this.$route.query.a == 1) {
      this.$router.replace({ name: "Login" });
      this.show_verified = 1;
    }
  },
  
  methods: {
    login() {
      this.$form.load('login-form')

      this.$axios
        .post("/api/v1/login", this.user)
        .then((response) => {
          this.navigateLogin(response.data);
        })
        .catch((err) => {
          this.$form.validate('login-form', err.response)
        });
    },

    navigateLogin(data) {
      this.$store.dispatch("setAuthUser", data).then(() => {
        if (!data.access_token) {
          this.$router.replace({
            name: "otp",
            params: { email: this.$route.params.id },
          });
          return;
        }
        this.$auth.setToken(data.access_token);
        this.$axios.defaults.headers.Authorization =
          "Bearer " + this.$auth.getToken();
        if (this.loggeduser.requires_password_change) {
          this.$router.replace({ name: "set-password" });
          return;
        }

        if (
          this.loggeduser.id_number == null &&
          !this.loggeduser.create_by_admin
        ) {
          this.$router.replace({ name: "verify.personal" });
          return;
        }

        if (
          !this.loggeduser.verified_phone &&
          this.loggeduser.id_number !== null
        ) {
          this.$router.replace({ name: "verify.phone" });
          return;
        }

        if (this.loggeduser.verified_phone) {
          this.$router.replace({ name: "dashboard" });
        }
      });
    },

    onSignInSuccess(googleUser) {
      const profile = googleUser.getBasicProfile();
      this.email = profile.getEmail();
      let auth = googleUser.getAuthResponse(true);
      this.access_token = auth.access_token;
      this.googleLogin();
    },

    onSignInError() {
      this.error = { status: 401, data: { message: "Google sign in failed" } };
    },

    googleLogin() {
      this.$axios
        .post("api/v1/auth/google", {
          access_token: this.access_token,
        })
        .then((response) => {
          this.navigateLogin(response.data);
        })
        .catch((err) => {
          this.error = { status: 401, data: { message: err.response.data } };
        });
    },
  },
};
</script>
